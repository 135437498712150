<template>
	<div>
		<div v-if="loading">
			<div style="background-color: white">
				<img v-if="loading" src="@/_srcv2/components/_shared/icon/200w.gif" />
			</div>
		</div>
	</div>
</template>

<script>
import GetPassKeysQuery from '@/_srcv2/composables/admin/GetPassKeysQuery.graphql'
import { useQuery } from '@vue/apollo-composable'
import { reactive } from '@vue/composition-api'
import Store from '@/store'
export default {
	name: 'GetPassDataComponent',
	setup() {
		const options = reactive({
			fetchPolicy: 'network-only',
		})
		const { onResult, loading } = useQuery(
			GetPassKeysQuery,
			() => ({
				id: 101,
			}),
			options,
		)
		onResult((result) => {
			console.log('from getPassDataComponent result', result)
			Store.dispatch('setAdminPassKey', result.data.__admin_pass[0])
			Store.dispatch('setPrivilegePassKey', result.data.__privilege_pass[0])
			Store.dispatch('setShowGetPassDataComponent', false)
			console.log(
				'Store.getters.getAdminPassKey',
				Store.getters.getAdminPassKey,
			)
		})
		return {
			loading,
		}
	},
}
</script>

<style></style>
