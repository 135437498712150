<template>
	<LayoutDefaultMobile>
		<div v-if="$store.getters.getShowGetPassDataComponent">
			<GetPassDataComponent />
		</div>
		<div v-if="!$store.getters.getShowGetPassDataComponent">
			<div>
				<b-field label="Password">
					<b-input v-model="password" type="password" value="" password-reveal>
					</b-input>
				</b-field>
				<b-field>
					<b-numberinput v-model="minutes"></b-numberinput>
				</b-field>
			</div>
			<div class="margin-top-30">
				<b-button class="is-info" @click="savePassKey" :disabled="!validated"
					>Set New Pass Key</b-button
				>
			</div>
		</div>
	</LayoutDefaultMobile>
</template>

<script>
import SetPrivilegePassMutation from '@/_srcv2/composables/admin/SetPrivilegePassMutation.graphql'
import InsertPrivilegePassMutation from '@/_srcv2/composables/admin/InsertPrivilegePassMutation.graphql'
import { useMutation } from '@vue/apollo-composable'
import { ref, computed, onMounted } from '@vue/composition-api'
import GetPassDataComponent from '@/_srcv2/composables/admin/GetPassDataComponent.vue'
import Store from '@/store'

export default {
	name: 'SetPrivilegePass',
	components: {
		GetPassDataComponent,
	},
	setup() {
		const password = ref('')
		const minutes = ref(30)
		onMounted(() => {
			Store.dispatch('setShowGetPassDataComponent', true)
		})

		// * set password
		const mutationVariable = computed(() => {
			return {
				privilege_id: 101,
				privilege_pass_key: password.value,
				created_at: Date.now(),
				valid_minutes: minutes.value,
			}
		})
		const {
			mutate: mutateSet,
			onDone: onDoneSet,
			onError: onErrorSet,
		} = useMutation(SetPrivilegePassMutation, () => ({
			variables: mutationVariable.value,
		}))
		onDoneSet(() => {
			alert('Privilege pass key is set.')
			password.value = ''
			Store.dispatch('setShowGetPassDataComponent', true)
		})
		onErrorSet((err) => console.log('Error: ', err))
		// todo ---------------------------------------------------------------------
		const {
			mutate: mutateInsert,
			onDone: onDoneInsert,
			onError: onErrorInsert,
		} = useMutation(InsertPrivilegePassMutation, () => ({
			variables: mutationVariable.value,
		}))
		onDoneInsert(() => {
			alert('Privilege pass key is created for the first time.')
			password.value = ''
			Store.dispatch('setShowGetPassDataComponent', true)
		})
		onErrorInsert((err) => console.log('Error: ', err))
		// todo ---------------------------------------------------------------------
		const savePassKey = () => {
			console.log('mutationVariable', mutationVariable.value)
			if (Store.getters.getPrivilegePassKey?.privilege_pass_key.length > 0) {
				mutateSet()
			} else {
				mutateInsert()
			}
		}
		// todo -----------------------------------------------------------------------
		const validated = computed(() => {
			if (password.value.length > 3) {
				return true
			} else {
				return false
			}
		})
		return {
			savePassKey,
			password,
			minutes,
			validated,
		}
	},
}
</script>
